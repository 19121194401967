import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import cls from 'clsx';
import { useStore } from '@/store';
import { formatter } from '@/utils/misc';
import { isToday } from '@/utils';
import dateUtils from './utils';
import { ROW, COL } from './constants';
import { ReactComponent as ArrowRight } from '@/assets/icons/caret-right.svg';
import { ReactComponent as ArrowLeft } from '@/assets/icons/caret-left.svg';
import { useDateTableKeyboardNav } from './hooks/useDateTableKeyboardNav';
import {
  IDateTableProps,
  CellType,
  ICellPrice,
  Cell
} from './DatePicker.types';
import styles from './datepicker.module.css';

const DateTable: React.FC<IDateTableProps> = ({
  date,
  onSelectDate,
  onHideMonth,
  selectedDate,
  initialFocus
}) => {
  const { t } = useTranslation();
  const { activityStore } = useStore();

  const currentYear = useMemo(() => date.getFullYear(), [date]);
  const currentMonth = useMemo(() => date.getMonth(), [date]);
  const currentDay = useMemo(() => date.getDate(), [date]);
  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const headerText = useMemo(() => {
    return t(`date.month.month${currentMonth + 1}`) + ' ' + currentYear;
  }, [t, currentMonth, currentYear]);

  const tablePrice = useMemo<ICellPrice>(
    () => activityStore.tablePrice,
    [activityStore.tablePrice]
  );

  const getCellType = useCallback(
    (day: number) => {
      const cellDate = new Date(currentYear, currentMonth, day);

      let type: CellType = 'disabled';
      const cellDateToString = dateUtils.dateToString(cellDate);

      if (tablePrice[cellDateToString]) type = 'normal';
      const cellDateYear = cellDate.getFullYear();
      const cellDateMonth = cellDate.getMonth();
      const cellDateDate = cellDate.getDate();

      if (isToday(cellDateYear, cellDateMonth, cellDateDate)) type = 'today';

      if (
        selectedDate &&
        cellDateToString === dateUtils.dateToString(selectedDate)
      ) {
        type = 'selected';

        if (isToday(cellDateYear, cellDateMonth, cellDateDate)) {
          type = 'todaySelected';
        }
      }

      return type;
    },
    [currentYear, currentMonth, tablePrice, selectedDate]
  );

  const getCellTitle = useCallback(
    (day: number) => {
      const cellDate = new Date(currentYear, currentMonth, day);
      return dateUtils.dateToString(cellDate);
    },
    [currentMonth, currentYear]
  );

  const tableCell = useMemo(() => {
    const month = dateUtils.getMonthArray(currentYear, currentMonth);
    const offset = dateUtils.getFirstDayOfMonth(date);
    const cell = dateUtils.getDefaultTableCell();
    for (let j = offset; j < COL; j++) {
      const day = month.shift();
      if (day) {
        cell[0][j].value = day.toString();
        cell[0][j].type = getCellType(day);
        cell[0][j].title = getCellTitle(day);
        cell[0][j].comboPrice = formatter.format(
          tablePrice[cell[0][j].title]?.price?.combo
        );
        cell[0][j].deckPrice = formatter.format(
          tablePrice[cell[0][j].title]?.price?.deck
        );
      }
    }
    for (let i = 1; i < ROW; i++) {
      for (let j = 0; j < COL; j++) {
        const day = month.shift();
        if (day) {
          cell[i][j].value = day.toString();
          cell[i][j].type = getCellType(day);
          cell[i][j].title = getCellTitle(day);
          if (tablePrice[cell[i][j].title]) {
            cell[i][j].comboPrice = formatter.format(
              tablePrice[cell[i][j].title]?.price.combo
            );
            cell[i][j].deckPrice = formatter.format(
              tablePrice[cell[i][j].title]?.price.deck
            );
          }
        }
      }
    }
    // remove last empty row
    if (cell[ROW - 1].every(cell => !cell.value)) {
      delete cell[ROW - 1];
    }
    return cell;
  }, [currentYear, currentMonth, date, getCellType, getCellTitle, tablePrice]);

  useDateTableKeyboardNav(headerRef, bodyRef, currentDay, initialFocus);

  const handleClick = useCallback(
    ({ value, comboPrice, deckPrice }: Cell) => {
      const day = Number(value);
      const cellDate = new Date(currentYear, currentMonth, day);
      if (comboPrice && deckPrice) {
        onSelectDate(cellDate);
      }
    },
    [currentYear, currentMonth, onSelectDate]
  );

  const handleMonthToggle = useCallback(() => {
    onHideMonth?.();
  }, [onHideMonth]);

  const isNextMonth = useMemo(() => {
    const now = new Date();
    return (
      currentYear > now.getFullYear() ||
      (currentYear === now.getFullYear() && currentMonth > now.getMonth())
    );
  }, [currentMonth, currentYear]);

  return (
    <div className={styles.dateTable}>
      <div className={styles.dateTableHeader} ref={headerRef}>
        {isNextMonth ? (
          <button
            onClick={handleMonthToggle}
            className={styles.dateTableHeaderIcon}
          >
            <ArrowLeft height={16} />
          </button>
        ) : (
          <div className={styles.dateTableHeaderIcon} />
        )}
        {headerText}
        {isNextMonth ? (
          <div className={styles.dateTableHeaderIcon} />
        ) : (
          <button
            onClick={handleMonthToggle}
            className={styles.dateTableHeaderIcon}
          >
            <ArrowRight height={16} />
          </button>
        )}
      </div>
      <div className={styles.dateTableBody} role="grid" ref={bodyRef}>
        <div className={styles.dateTableWeek}>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.sun')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.mon')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.tue')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.wed')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.thu')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.fri')}</div>
          <div className={styles.dateTableWeekItem}>{t('date.weeks.sat')}</div>
        </div>
        <div className={styles.dateTableMonth}>
          {tableCell.flat().map((cell, index) => (
            <div
              key={index}
              className={cls(styles.dateTablePriceCell, {
                [styles.disabled]: cell.type === 'disabled',
                [styles.selected]:
                  cell.type === 'selected' || cell.type === 'todaySelected',
                [styles.unavailable]:
                  (cell.type === 'todaySelected' || cell.type === 'selected') &&
                  !cell.comboPrice &&
                  !cell.deckPrice
              })}
            >
              <div className={styles.dateTableCell}>
                {cell.value && (
                  <button
                    type="button"
                    className={cls(styles.dateTableCellButton, {
                      [styles.today]:
                        (cell.type === 'today' ||
                          cell.type === 'todaySelected') &&
                        (cell.comboPrice || cell.deckPrice),
                      [styles.selected]:
                        cell.type === 'selected' ||
                        cell.type === 'todaySelected',
                      [styles.disabled]:
                        cell.type === 'disabled' ||
                        (!cell.comboPrice && !cell.deckPrice)
                    })}
                    onClick={() => handleClick(cell)}
                    title={getCellTitle(Number(cell.value))}
                    disabled={cell.type === 'disabled'}
                  >
                    {cell.value}
                    {!!Object.keys(tablePrice).length && (
                      <div className={styles.dateTablePriceCellLabels}>
                        <label
                          className={cls(styles.dateTablePriceCellSetLabel, {
                            [styles.disabled]: cell.type === 'disabled'
                          })}
                        >
                          {cell.comboPrice}
                        </label>
                        <label
                          className={cls(styles.dateTablePriceCellDeckLabel, {
                            [styles.disabled]: cell.type === 'disabled'
                          })}
                        >
                          {cell.deckPrice}
                        </label>
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(DateTable);
